<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link to="/"
                   class="brand-logo d-flex align-center">
        <v-img :src="appLogo"
               max-height="30px"
               max-width="30px"
               alt="logo"
               contain
               class="me-3 "></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col lg="8"
               class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <v-img class="auth-mask-bg" :src="getPhoto"></v-img>
            <!-- triangle bg -->
            <!--<img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />-->
            <!-- tree -->
            <!--<v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img>-->
            <!-- 3d character -->

            <div class="d-flex align-center h-full pa-16 pe-0">

              <!--<img v-if="this.selectedImage" :src="this.getImage()"/>

              <img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"

                :src="this.selectedImage"
              />-->
            </div>
          </div>
        </v-col>


        <v-col lg="4"
               class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <!--no company -->
            <v-col v-if="showNoCompany" cols="12"
                   sm="8"
                   md="6"
                   lg="12"
                   class="mx-auto">

              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Welcome back to Epiito Cloud
                  </p>
                  <p class="mb-2 text--primary">
                    It looks like you haven't been granted access to any groups yet !
                    please contact the administration team of the group you wish to join to be granted access.
                  </p>
                </v-card-text>
              </v-card>
              <v-btn block
                     color="primary"
                     type="submit"
                     class="mt-6 mb-6"
                     @click="closeShowNoCompanyPanel">
                close
              </v-btn>



              <!--<div class="d-flex justify-center mb-6">
                <p v-show="!deleteAccountToggle" @click="deleteAccountToggle = !deleteAccountToggle">To delete your account: view more</p>

                <div v-show="deleteAccountToggle">
                  <p @click="deleteAccountToggle = !deleteAccountToggle">To delete your account: view less</p>

                  <h3>Before you go, keep in mind:</h3>
                  <p>Account deletion is final. There will be no way to restore your account.</p>
                  <p>All your projects, images etc. will be unlinked to your account.</p>





                  <v-dialog v-model="DeleteAccountModal" persistent max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="error"
                             class="me-3 mt-5"
                             @click="DeleteAccountModal=true">
                        <span class="d-none d-sm-block">Delete account</span>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5">
                        Delete account?
                      </v-card-title>
                      <v-card-text>Before you go, keep in mind:</v-card-text>
                      <v-card-text>Account deletion is final. There will be no way to restore your account.</v-card-text>
                      <v-card-text>All your projects, images etc. will be unlinked to your account.</v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error"
                               :loading="loading"
                               class="me-3 mt-5"
                               @click="DeleteAccountasdasd">
                          <span class="d-none d-sm-block">Confirm deletion</span>
                        </v-btn>
                        <v-btn color="primary"
                               class="me-3 mt-5"
                               @click="DeleteAccountModal=false">
                          <span class="d-none d-sm-block">Cancel</span>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>



                </div>

              </div>-->
            </v-col>

            <!--standard login-->
            <v-col v-if="!showNoCompany"
                   cols="12"
                   sm="8"
                   md="6"
                   lg="12"
                   class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Welcome back to Epiito Cloud
                  </p>
                  <p class="mb-2">
                    Please sign-in to your account to continue
                  </p>
                </v-card-text>
                <!--<v-card-text>
                  <v-alert
                    text
                    color="primary"
                  >
                    <small class="d-block mb-1">
                      Admin Email: <strong>admin@materio.com</strong> / Pass: <strong>admin</strong>
                    </small>
                    <small>
                      Client Email: <strong>client@materio.com</strong> / Pass: <strong>client</strong>
                    </small>
                  </v-alert>
                </v-card-text>-->
                <!-- login form -->
                <v-card-text>
                  <v-form ref="loginForm"
                          @submit.prevent="handleFormSubmit">
                    <v-text-field v-model="email"
                                  outlined
                                  label="Email"
                                  placeholder="email"
                                  :error-messages="errorMessages.email"
                                  :rules="[validators.required, validators.emailValidator]"
                                  hide-details="auto"
                                  class="mb-6"></v-text-field>

                    <v-text-field v-model="password"
                                  outlined
                                  :type="isPasswordVisible ? 'text' : 'password'"
                                  label="Password"
                                  :error-messages="errorMessages.password"
                                  placeholder="Password"
                                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                                  :rules="[validators.required]"
                                  hide-details="auto"
                                  class="mb-2"
                                  @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>

                    <div class="d-flex align-center justify-space-between flex-wrap">
                      <!--<v-checkbox
                        hide-details
                        label="Remember Me"
                        class="mt-0"
                      >
                      </v-checkbox>-->
                      <!-- forget link -->
                      <router-link :to="{name:'auth-forgot-password'}"
                                   class="ms-3">
                        Forgot Password?
                      </router-link>
                    </div>





                    <p v-html="errorMessages.login" class="error--text"></p>

                    <v-btn block
                           color="primary"
                           type="submit"
                           class="mt-6">
                      Login
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    New on our platform?
                  </p>
                  <router-link :to="{name:'auth-register'}">
                    Create an account
                  </router-link>
                </v-card-text>

                <!-- divider -->
                <!--<v-card-text class="d-flex align-center mt-2">
                  <v-divider></v-divider>
                  <span class="mx-5">or</span>
                  <v-divider></v-divider>
                </v-card-text>-->
                <!-- socail links -->
                <!--<v-card-actions class="d-flex justify-center">
                  <v-btn
                    v-for="link in socialLink"
                    :key="link.icon"
                    icon
                    class="ms-1"
                  >
                    <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
                      {{ link.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-actions>-->
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
  import { ref, getCurrentInstance, onBeforeMount, on } from '@vue/composition-api'
  import { required, emailValidator } from '@core/utils/validation'
  import axios from '@axios'
  import { useRouter } from '@core/utils'
  import themeConfig from '@themeConfig'
  import { auth, db, storage } from '../Firebase/Firebase.service'
  import { signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
  import { collectionGroup, query, where, collection, doc, getDocs, getDoc } from "firebase/firestore"
  import { ref as storageRef, getDownloadURL } from "firebase/storage";



  export default {

    setup() {
      // Template Ref
      const loginForm = ref(null)

      const vm = getCurrentInstance().proxy
      const { router } = useRouter()

      const isPasswordVisible = ref(false)
      const showNoCompany = ref(false)

      const email = ref('')
      const password = ref('')

      //const email = ref('admin@materio.com')
      //const password = ref('admin')
      const errorMessages = ref([])
      const socialLink = [
        {
          icon: mdiFacebook,
          color: '#4267b2',
          colorInDark: '#4267b2',
        },
        {
          icon: mdiTwitter,
          color: '#1da1f2',
          colorInDark: '#1da1f2',
        },
        {
          icon: mdiGithub,
          color: '#272727',
          colorInDark: '#fff',
        },
        {
          icon: mdiGoogle,
          color: '#db4437',
          colorInDark: '#db4437',
        },
      ]

      const closeShowNoCompanyPanel = () => {
        showNoCompany.value = !showNoCompany.value
      }

      const handleFormSubmit = () => {

        const isFormValid = loginForm.value.validate()

        if (!isFormValid) return


        vm.login(errorMessages, showNoCompany, email, password, vm, router)
        return;

        axios
          .post('/auth/login', { email: email.value, password: password.value })
          .then(response => {
            const { accessToken } = response.data



            // ? Set access token in localStorage so axios interceptor can use it
            // Axios Interceptors: https://github.com/axios/axios#interceptors
            localStorage.setItem('accessToken', accessToken)

            return response
          })
          .then(() => {
            axios.get('/auth/me').then(response => {
              const { user } = response.data
              const { ability: userAbility } = user

              // Set user ability
              // ? https://casl.js.org/v5/en/guide/intro#update-rules
              vm.$ability.update(userAbility)

              // Set user's ability in localStorage for Access Control
              localStorage.setItem('userAbility', JSON.stringify(userAbility))

              // We will store `userAbility` in localStorage separate from userData
              // Hence, we are just removing it from user object
              delete user.ability

              // Set user's data in localStorage for UI/Other purpose
              localStorage.setItem('userData', JSON.stringify(user))

              // On success redirect to home
              router.push('/')
            })
          })
          .catch(error => {
            // TODO: Next Update - Show notification
            errorMessages.value = error.response.data.error
          })
      }


      return {
        handleFormSubmit,
        isPasswordVisible,
        showNoCompany,
        closeShowNoCompanyPanel,
        email,
        password,
        errorMessages,
        socialLink,
        icons: {
          mdiEyeOutline,
          mdiEyeOffOutline,
        },
        validators: {
          required,
          emailValidator,
        },

        // themeConfig
        appName: themeConfig.app.name,
        appLogo: themeConfig.app.logo,

        // Template Refs
        loginForm,
      }
    },

    methods: {
      defineAbility(ability) {
        const userAbility = [];

        userAbility.push({ action: 'read', subject: 'public' });

        userAbility.push({ action: 'read', subject: 'account' });

        if (ability.buildsDownload) {
          userAbility.push({ action: 'read', subject: 'buildsDownload' });
        }
        if (ability.projectCreate) {
          userAbility.push({ action: 'create', subject: 'project' });
        }
        if (ability.projectRead) {
          userAbility.push({ action: 'read', subject: 'project' });
        }
        if (ability.projectUpdate) {
          userAbility.push({ action: 'update', subject: 'project' });
        }
        if (ability.projectDelete) {
          userAbility.push({ action: 'delete', subject: 'project' });
        }
        if (ability.rolesCreate) {
          userAbility.push({ action: 'create', subject: 'roles' });
        }
        if (ability.rolesRead) {
          userAbility.push({ action: 'read', subject: 'roles' });
        }
        if (ability.rolesUpdate) {
          userAbility.push({ action: 'update', subject: 'roles' });
        }
        if (ability.rolesDelete) {
          userAbility.push({ action: 'delete', subject: 'roles' });
        }
        if (ability.usersInvite) {
          userAbility.push({ action: 'invite', subject: 'users' });
        }
        if (ability.usersRead) {
          userAbility.push({ action: 'read', subject: 'users' });
        }
        if (ability.usersUpdate) {
          userAbility.push({ action: 'update', subject: 'users' });
        }
        if (ability.usersDelete) {
          userAbility.push({ action: 'delete', subject: 'users' });
        }
        return userAbility;
      },

      async login(errorMessages, showNoCompany, email, password, vm, router) {
        //do login
        signInWithEmailAndPassword(auth, email.value, password.value).then((userCredential) => {
          if (!userCredential.user.emailVerified) {
            sendEmailVerification(auth.currentUser).then(() => {
              errorMessages.value = { login: "User not verified, please check your email" }
            });
            return
          }


          localStorage.setItem('accessToken', userCredential.user.accessToken)

          //get user document
          const userDocRef = doc(db, "users", userCredential.user.uid)
          getDoc(userDocRef).then((userDoc) => {
            //get all companies the user is apart of
            const membershipsQuery = query(collectionGroup(db, 'memberships'), where('user', '==', userDocRef));
            getDocs(membershipsQuery).then((membershipsDocs) => {
              //create company and roles collection
              //get user role attached to the company

              if (membershipsDocs.size == 0) {

                showNoCompany.value = true;
                return;
              }


              const promises = []  // collect all promises here
              membershipsDocs.forEach(membershipDoc => {
                var promise = vm.getRoles(membershipDoc)
                promises.push(promise)
              })
              Promise.all(promises).then(companiesAndRolesCollection => {
                //when getting roles are completed, create user
                const logincompany = companiesAndRolesCollection[0]

                var user =
                {
                  id: userCredential.user.uid,
                  fullName: userDoc.data().username,
                  username: userDoc.data().username,
                  password: password.value.value,
                  avatar: userDoc.data().avatarImage,
                  email: userDoc.data().email,
                  role: logincompany.role.name
                }
                //add all companies to localstorage
                localStorage.setItem('companiesAndRoles', JSON.stringify(companiesAndRolesCollection))



                const userAbility = this.defineAbility(logincompany.role);

                if (user.avatar == "") {
                  user.avatar = ""
                  //add user's ability and currentCompany to localstorage

                  vm.$ability.update(userAbility)
                  localStorage.setItem('userAbility', JSON.stringify(userAbility))
                  localStorage.setItem('company', JSON.stringify(logincompany))

                  //// Set user's data in localStorage for UI/Other purpos
                  localStorage.setItem('userData', JSON.stringify(user))

                  router.push('/').catch(err => {
                    // Ignore the vuex err regarding  navigating to the page they are already on.
                    if (
                      err.name !== 'NavigationDuplicated' &&
                      !err.message.includes('Avoided redundant navigation to current location')
                    ) { }
                  })
                }
                else {
                  getDownloadURL(storageRef(storage, user.avatar)).then((avatarUrl) => {
                    user.avatar = avatarUrl
                    //add user's ability and currentCompany to localstorage
                    vm.$ability.update(userAbility)
                    localStorage.setItem('userAbility', JSON.stringify(userAbility))
                    localStorage.setItem('company', JSON.stringify(logincompany))

                    //// Set user's data in localStorage for UI/Other purpos
                    localStorage.setItem('userData', JSON.stringify(user))

                    router.push('/').catch(err => {
                      // Ignore the vuex err regarding  navigating to the page they are already on.
                      if (
                        err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')
                      ) { }
                    })
                  })
                }
              }).then(() => {

              })
            }).catch((error) => {
              errorMessages.value = { login: "Unable fetch data, please check your internet connection" };
              console.log(error)
              return
            }) //get membership docs
          }).catch((error) => {
            console.log(error)
            errorMessages.value = { login: "Unable fetch data, please check your internet connection" };
            return
          }) // get user doc
        }).catch((error) => {
          switch (error.code) {
            case "auth/too-many-requests":
              errorMessages.value = { login: "Too many login requests with the wrong password, please reset your password" };
              break
            case "auth/network-request-failed":
              errorMessages.value = { login: "Login request failed, make sure you're connected to the internet and try again" };
              break
            case "auth/user-disabled":
              errorMessages.value = { login: "Your account has been temporarily disabled, please contact a Epiito Employee or write a ticket at <a href='https://epiito.atlassian.net/servicedesk/customer/portals'>ServiceDesk</a> if you didn't authorise this change" };
              break
            default:
              errorMessages.value = { password: "wrong password" };
          }
          console.log(error)

          return
        }) //sign in close
      },
      async getRoles(membershipDoc) {
        var roleDocRef = membershipDoc.data().role
        var roleDoc = await getDoc(roleDocRef)
        var companyDoc = await getDoc(membershipDoc.ref.parent.parent)
        var companyDocData = companyDoc.data()
        companyDocData.id = companyDoc.id
        companyDocData.path = companyDoc.ref.path

        var membership = membershipDoc.data()
        membership.path = membershipDoc.ref.path
        membership.id = membershipDoc.id

        var cAndR = {
          company: companyDocData,
          membership: membership,
          role: roleDoc.data(),
          subject: 'all'
        }
        return cAndR
      },
    },

    computed: {
      getPhoto() {
        return this.images[Math.floor(Math.random() * this.images.length)]
      }
    },

    data: function () {
      return {
        images: [
          require('@/assets/epiito/images/Manufactoring.jpg'),
          require('@/assets/epiito/images/Epiito_Studio.jpg'),
          require('@/assets/epiito/images/HeadMountedDisplay.jpg'),
          require('@/assets/epiito/images/Lady_VR.jpg'),
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '@core/preset/preset/pages/auth.scss';
</style>





